
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { ArticleTableOfContentsBlokInterface } from '~/types/articlePage';
import { StoryblokComponentInterface } from '~/types/storyblok';
import { anchorTagFormat } from '~/utils/filters';
import breakpoints from '~/mixins/breakpoints';

export default defineComponent({
    name: 'ArticleTableOfContentsComponent',
    mixins: [breakpoints],
    props: {
        blok: {
            type: Object as PropType<ArticleTableOfContentsBlokInterface>,
            required: true,
        },
        h2List: {
            type: Array<StoryblokComponentInterface>,
            required: true,
        },
        stickyActive: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        anchorTagFormat,
    },
});
