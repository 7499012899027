import { ref, Ref, onMounted, onUnmounted, computed } from 'vue';
import sassBreakpoints from '@energysage/es-bs-base/scss/variables/_breakpoints.scss';

interface Breakpoints {
    [key: string]: string;
}

const BREAKPOINTS: Breakpoints = {
    xs: sassBreakpoints.xs,
    sm: sassBreakpoints.sm,
    md: sassBreakpoints.md,
    lg: sassBreakpoints.lg,
    xl: sassBreakpoints.xl,
    xxl: sassBreakpoints.xxl,
};

export default {
    setup() {
        // eslint-disable-next-line no-unused-vars
        const breakpointMediaQueries: { [key in keyof Breakpoints]?: MediaQueryList } = {};
        const breakpointChangeListeners: { [key: string]: () => void } = {};
        const breakpointMatches: { [key: string]: Ref<boolean> } = {};

        if (process.client) {
            // Set up media queries, reactive references and event listeners for each breakpoint
            Object.entries(BREAKPOINTS).forEach(([key, value]) => {
                const mediaQueryList = window.matchMedia(`(min-width: ${value})`) as MediaQueryList;
                breakpointMediaQueries[key] = mediaQueryList;
                breakpointMatches[key] = ref<boolean>(mediaQueryList?.matches || false);
                breakpointChangeListeners[key] = () => {
                    breakpointMatches[key].value = mediaQueryList?.matches || false;
                };
            });
        }

        // On component mount, add change event listeners for each breakpoint
        onMounted(() => {
            Object.keys(breakpointMatches).forEach((key) => {
                breakpointMediaQueries[key]?.addEventListener('change', breakpointChangeListeners[key]);
            });
        });

        // On component unmount, remove the event listeners
        onUnmounted(() => {
            Object.keys(breakpointMatches).forEach((key) => {
                breakpointMediaQueries[key]?.removeEventListener('change', breakpointChangeListeners[key]);
            });
        });

        // Individual breakpoint computed properties
        const isXS = computed(() => breakpointMatches.xs?.value || false);
        const isSM = computed(() => breakpointMatches.sm?.value || false);
        const isMD = computed(() => breakpointMatches.md?.value || false);
        const isLG = computed(() => breakpointMatches.lg?.value || false);
        const isXL = computed(() => breakpointMatches.xl?.value || false);
        const isXXL = computed(() => breakpointMatches.xxl?.value || false);

        // Compute values for device categories
        const isDesktop = computed(() => breakpointMatches.lg?.value || false);
        const isTablet = computed(() => (breakpointMatches.md?.value && !breakpointMatches.lg?.value) || false);
        const isMobile = computed(
            () =>
                ((breakpointMatches.xs?.value || breakpointMatches.sm?.value) &&
                    !breakpointMatches.lg?.value &&
                    !breakpointMatches.md?.value) ||
                false,
        );

        // FIXME: Be nice to namespace this but it ends up being a cyclic dependency somehow
        return { isXS, isSM, isMD, isLG, isXL, isXXL, isDesktop, isTablet, isMobile };
    },
};
